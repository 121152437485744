import { API, graphqlOperation } from 'aws-amplify';

const adminSearchAllAccountsQuery = `
query adminSearchAllAccounts($searchString: String!) {
  adminSearchAllAccounts(searchString: $searchString) {
    __typename
    ... on Advisor {
      id
      email
      acceptTermsOfService
      accountStatus
      avatarUrl
      biography
      boards {
        appliedBoards {
          id
          companyName
          companyLogo
          slug
          createdAt
          isComplimentaryBoardPosition
          boardType
          isFreemium
          isAcPlus
        }
        memberBoards {
          id
          comesFromNonBulkInvite
          companyName
          companyLogo
          slug
          createdAt
          isComplimentaryBoardPosition
          boardType
          isFreemium
          isAcPlus
        }
        invitedBoards {
          companyName
          companyLogo
          id
          slug
          createdAt
          boardType
          isFreemium
          isAcPlus
          opportunityStage
          boardStatus
          invitation {
            invitationId,
            bulk
          }
        }
      }
      boardCompanies
      companyName
      countAnswers
      countComments
      countCompanies
      createdAt
      displayName
      freeTrialEndsOn
      freeB2BAccountStatus
      givenName
      headline
      image {
        imageKey
      }
      industryTagIds
      onboardingProcess
      onboardingStatus
      phoneNumber {
        callingCode
        country
        countryCode
        internationalPhoneNumber
        phoneNumber
      }
      positionTagIds
      postalCode
      publicProfileUrl
      recurlyAccountCode
      recurlyPlanCode
      recurlySubscriptionUuid
      salesforceContactId
      surName
      title
      updatedAt
      featuredAdvisor
      privateProfile
    }
    ... on Company {
      id
      bannedAdvisors
      boardSeatsAllowed
      boardSeatsAvailable
      boardType
      companyName
      companySize
      contacts {
        acceptTermsOfService
        accountStatus
        biography
        companyId
        createdAt
        displayName
        email
        givenName
        id
        image {
          id
          location
        }
        isBoardOwner
        onboardingProcess
        phoneNumber {
          callingCode
          country
          countryCode
          internationalPhoneNumber
          phoneNumber
        }
        surName
        title
        updatedAt
      }
      countAdvisors
      createdAt
      desiredSkillsTagIds
      freeTrialEndsOn
      image {
        id
        location
      }
      industryTagIds
      invitedAdvisors {
        id
        inviteDate
      }
      isPrivate
      isPublic
      instantJoinEnabled
      complimentaryBoardPosition
      latestNewAdvisorsITJ
      onboardingStatus
      opportunityId
      opportunityStage
      perks
      postalCode
      previousCompanyMembers
      recurlyAccountCode
      recurlyPlanCode
      recurlySubscriptionUuid
      salesforceContactId
      slug
      storyAndMission
      userSegment
      upcomingBoardMeeting
      updatedAt
      website
    }
      ... on CompanyContact {
      acceptTermsOfService
      accountStatus
      biography
      companyId
      createdAt
      displayName
      email
      givenName
      id
      image {
        id
        location
      }
      isBoardOwner
      onboardingProcess
      phoneNumber {
        callingCode
        country
        countryCode
        internationalPhoneNumber
        phoneNumber
      }
      surName
      title
      updatedAt
    }
  }
}
`;
const adminSearchByCognitoIdQuery = `
query adminSearchAllAccounts($cognitoId: String!) {
  adminSearchAllAccounts(cognitoId: $cognitoId) {
    __typename
    ... on Advisor {
      id
      email
      acceptTermsOfService
      accountStatus
      avatarUrl
      biography
      boardCompanies
      boards {
        appliedBoards {
          id
          companyName
          companyLogo
          slug
          createdAt
          isComplimentaryBoardPosition
          boardType
          isFreemium
          isAcPlus
        }
        memberBoards {
          id
          comesFromNonBulkInvite
          companyName
          companyLogo
          slug
          createdAt
          isComplimentaryBoardPosition
          boardType
          isFreemium
          isAcPlus
        }
        invitedBoards {
          companyName
          companyLogo
          id
          slug
          createdAt
          boardType
          isFreemium
          isAcPlus
          opportunityStage
          boardStatus
          invitation {
            invitationId,
            bulk
          }
        }
      }
      companyName
      countAnswers
      countComments
      countCompanies
      createdAt
      displayName
      image {
        imageKey
      }
      freeTrialEndsOn
      freeB2BAccountStatus
      givenName
      headline
      industryTagIds
      onboardingProcess
      onboardingStatus
      phoneNumber {
        callingCode
        country
        countryCode
        internationalPhoneNumber
        phoneNumber
      }
      positionTagIds
      postalCode
      publicProfileUrl
      recurlyAccountCode
      recurlyPlanCode
      recurlySubscriptionUuid
      salesforceContactId
      surName
      title
      updatedAt
      featuredAdvisor
    }
    ... on Company {
      id
      bannedAdvisors
      boardSeatsAllowed
      boardSeatsAvailable
      companyName
      companySize
      contacts {
        acceptTermsOfService
        accountStatus
        biography
        companyId
        createdAt
        displayName
        email
        givenName
        id
        image {
          id
          location
        }
        isBoardOwner
        onboardingProcess
        phoneNumber {
          callingCode
          country
          countryCode
          internationalPhoneNumber
          phoneNumber
        }
        surName
        title
        updatedAt
      }
      countAdvisors
      createdAt
      desiredSkillsTagIds
      freeTrialEndsOn
      image {
        id
        location
      }
      industryTagIds
      invitedAdvisors {
        id
        inviteDate
      }
      isPrivate
      isPublic
      instantJoinEnabled
      complimentaryBoardPosition
      latestNewAdvisorsITJ
      onboardingStatus
      opportunityId
      opportunityStage
      perks
      postalCode
      previousCompanyMembers
      recurlyAccountCode
      recurlyPlanCode
      recurlySubscriptionUuid
      salesforceContactId
      slug
      storyAndMission
      userSegment
      upcomingBoardMeeting
      updatedAt
      website
    }
      ... on CompanyContact {
      acceptTermsOfService
      accountStatus
      biography
      companyId
      createdAt
      displayName
      email
      givenName
      id
      image {
        id
        location
      }
      isBoardOwner
      onboardingProcess
      phoneNumber {
        callingCode
        country
        countryCode
        internationalPhoneNumber
        phoneNumber
      }
      surName
      title
      updatedAt
    }
  }
}
`;

const getRecurlyPlansQuery = `
  query($accountType: String!, $purchasingChannel: String!) {
    getRecurlyPlans(accountType: $accountType, purchasingChannel: $purchasingChannel) {
      displayOrder
      boardsCanJoinCount
      boardSeatsAllowedCount
      isPoeEligible
      isPrivate
      legacyPlanCode
      lengthOfTrialInDays
      recurlyAction
      recurlyPlanCode
      recurlyPlanCodeLabel
      salesforceValues {
        tier
      }
    }
  }
`;

const getAllRecurlyPlansQuery = `
  query {
    getRecurlyPlans {
      accountType
      boardsCanJoinCount
      boardSeatsAllowedCount
      isActive
      isPrivate
      recurlyPlanCode
      recurlyPlanCodeLabel
      recurlyPlanCodeDisplayName
      lengthOfTrialInDays
      recurlyPlanCodePrice
      recurlyPlanCodeTerm
      checkoutRedirectUrl
      onboardingRedirectUrl
    }
  }
`;

const getInsightsQuery = `
  query($status: String!) {
    getInsightsV2(status: $status) {
      body
      createdAt
      function
      icon
      id
      kpis
      priority
      status
      title
      updatedAt
      url
    }
  }
`;

const getInsightQuery = `
  query($id: String!) {
    getInsight(id: $id) {
      body
      function
      icon
      id
      kpis
      priority
      status
      title
      url
    }
  }
`;

const addInsightMutation = `
  mutation addInsight(
    $body: String,
    $function: String,
    $icon: String,
    $kpis: [String],
    $priority: Int,
    $status: String,
    $title: String,
    $url: String
  ) {
    addInsightV2(
      body: $body,
      function: $function,
      icon: $icon,
      status: $status,
      title: $title,
      url: $url,
      kpis: $kpis,
      priority: $priority
    ) {
      id
    }
  }
`;

const updateInsightMutation = `
  mutation updateInsight(
    $id: String!,
    $body: String, 
    $function: String,
    $icon: String,
    $kpis: [String],
    $priority: Int,
    $status: String,
    $title: String,
    $url: String
  ) {
    updateInsightV2(
      id: $id,
      body: $body,
      function: $function,
      icon: $icon,
      kpis: $kpis,
      priority: $priority,
      status: $status,
      title: $title,
      url: $url
    ) {
      id
    }
  }
`;

const getAdminsQuery = `
  query {
    getAdminUsers {
      id
      accountType
      accountStatus
      companyName
      email
      givenName
      surName
      previousLastLoginTimestamp
    }
  }
`;

const getAdminQuery = `
  query($id: String!) {
    getAdmin(id: $id) {
      id
      accountType
      accountStatus
      companyName
      email
      givenName
      surName
      phoneNumber
      {
        callingCode
        country
        countryCode
        internationalPhoneNumber
        phoneNumber
      }
      title
      salesforceContactId
    }
  }
`;

const addAdminMutation = `
  mutation (
    $accountType: String,
    $companyName: String,
    $email: String,
    $givenName: String,
    $surName: String,
    $phoneNumber: String,
    $phoneCountryCode: String,
    $title: String,
    $salesforceContactId: String
  ) {
    addAdminUser(
      accountType: $accountType,
      companyName: $companyName,
      email: $email,
      givenName: $givenName,
      surName: $surName,
      phoneNumber: $phoneNumber,
      phoneCountryCode: $phoneCountryCode,
      title: $title,
      salesforceContactId: $salesforceContactId
    ) {
      id
    }
  }
`;

const updateAdminMutation = `
  mutation (
    $id: String!,
    $companyName: String,
    $givenName: String,
    $surName: String,
    $phoneNumber: String,
    $phoneCountryCode: String,
    $title: String,
    $salesforceContactId: String
  ) {
    updateAdminUser(
      id: $id,
      companyName: $companyName,
      givenName: $givenName,
      surName: $surName,
      phoneNumber: $phoneNumber,
      phoneCountryCode: $phoneCountryCode,
      title: $title,
      salesforceContactId: $salesforceContactId
    ) {
      id
    }
  }
`;

const updateAdminAccountStatusMutation = `
  mutation (
    $id: String!,
    $accountStatus: String!,
  ) {
    updateAdminUserAccountStatus(
      id: $id,
      accountStatus: $accountStatus
    ) {
      id
    }
  }
`;

const getNewAdvisorsQuery = `
  query(
    $companyId: String
    $offset: Int  
  ) {
    getNewAdvisors(
      companyId: $companyId
      offset: $offset
    ) {
      advisors {
        id
      }
      offset
    }
  }
`;

const adminInviteToJoinMutation = `
mutation(
  $advisorId: String!,
  $companyId: String!
) {
  adminInviteToJoin(
    advisorId: $advisorId,
    companyId: $companyId
  )
}
`;

const adminBulkInviteFinishedMutation = `
mutation(
  $companyId: String!
) {
  adminBulkInviteFinished(
    companyId: $companyId
  )
}
`;

const sendPauseCompanyNoticeMutation = `
mutation(
  $companyId: String!
) {
  sendPauseCompanyNotice(
    companyId: $companyId
  )
}
`;

const adminSignUpMutation = `
  mutation(
    $boardSeatsAllowedCount: Int,
    $companyName: String!,
    $email: String!,
    $givenName: String!,
    $onboardingExperienceUpsell: Boolean,
    $phoneCountryCode: String!,
    $phoneNumber: String!,
    $postalCode: String,
    $recurlyAccountCode: String,
    $userSegment: String,
    $recurlyBillingToken: String,
    $recurlyPlanCode: String!,
    $surName: String!,
    $urlOfCheckoutPage: String!
  ) {
    adminSignUp(
    boardSeatsAllowedCount: $boardSeatsAllowedCount,
    companyName: $companyName,
    email: $email,
    givenName: $givenName,
    onboardingExperienceUpsell: $onboardingExperienceUpsell,
    phoneCountryCode: $phoneCountryCode,
    phoneNumber: $phoneNumber,
    postalCode: $postalCode,
    recurlyAccountCode: $recurlyAccountCode,
    userSegment: $userSegment,
    recurlyBillingToken: $recurlyBillingToken,
    recurlyPlanCode: $recurlyPlanCode,
    surName: $surName,
    urlOfCheckoutPage: $urlOfCheckoutPage
  ) {
      accountType
      onboardingProcess
    }
  }
`;

const updateAdvisorAccountStatusMutation = `
  mutation($id: String!, $accountStatus: String!) {
    updateAdvisorAccountStatus(id: $id, accountStatus: $accountStatus) {
      id
    }
  }
`;
const updateCompanyContactAccountStatusMutation = `
mutation($id: String!, $accountStatus: String!) {
  updateCompanyContactAccountStatus(id: $id, accountStatus: $accountStatus) {
    id
  }
}
`;
const deleteAdvisorCCPAMutation = `
mutation($advisorId: String, $email: String) {
  CCPADeleteUser(advisorId: $advisorId, email: $email)
}
`;

const deleteCompanyCCPAMutation = `
mutation($companyId: String!) {
  deleteCompany(companyId: $companyId)
}
`;

const getRecurlyPlan = `
  query getAdminConsoleRecurlyPlan($recurlyPlanId: String!) {
    getAdminConsoleRecurlyPlan(recurlyPlanId: $recurlyPlanId) {
      recurlyPlanCode
      recurlyPlanCodeLabel
      accountType
      recurlyPlanCodePrice
      recurlyPlanCodeTerm
      lengthOfTrialInDays
      boardsCanJoinCount
      boardSeatsAllowedCount
      isActive
      isPrivate
      meetingsEnabled
      onboardingRedirectUrl
      upgradeUrl
      maxBoardsUpgradeUrl
      specialOffersUrl
      checkoutRedirectUrl
      isDualCapable
      isPoeEligible
      isUpgrade
      legacyPlanCode
      recurlyAction
      recurlyPlanCodeDisplayName
      dualRecurlyAction
      isLegacy
      earlyConversionAndUpgradePlancode
      isFreemium
      freemiumUpgradeOne
      freemiumUpgradeTwo
      isAcPlus
      b2bFreeTrialMonthlyUpgrade1
      b2bFreeTrialAnnualUpgrade1
      b2bFreeTrialMonthlyUpgrade2
      b2bFreeTrialAnnualUpgrade2
      acPlusUpgradeOptionOne
      acPlusUpgradeOptionTwo
      b2bOnboardingCalendar
      b2cFreemiumMonthlyUpgrade1
      b2cFreemiumMonthlyUpgrade2
      b2cFreemiumMonthlyUpgrade3
      b2cFreemiumAnnualUpgrade1
      b2cFreemiumAnnualUpgrade2
      b2cFreemiumAnnualUpgrade3
      salesforceValues {
        premierOnboardingExperience
        premierOnboardingPurchaseDate
        tier
        purchasingChannel
        billingCycle
        premierOnboardingSource
        memberType
        premierOnboardingPaymentStatus
        closeOnOwnLead
        status
      }
    }
  }
`;

const editFreeTrialEndsOn = `
  mutation($companyId: String!, $freeTrialEndsOn: AWSDateTime) {
    editFreeTrialCompany(companyId: $companyId, freeTrialEndsOn: $freeTrialEndsOn) {
      id
      freeTrialEndsOn
    }
  }
`;

export function getRecurlyPlanCall(RECURLY_PLAN_CODE) {
  return API.graphql(
    graphqlOperation(getRecurlyPlan, {
      recurlyPlanId: RECURLY_PLAN_CODE,
    })
  );
}

const updateRecurlyPlan = ` 
  mutation updateRecurlyPlan(
    $accountType: String!,
    $boardsCanJoinCount: Int,
    $checkoutRedirectUrl: String,
    $displayOrder: Int,
    $boardSeatsAllowedCount: Int,
    $isDualCapable: Boolean,
    $isPoeEligible: Boolean,
    $isPrivate: Boolean,
    $lengthOfTrialInDays: Int!,
    $onboardingRedirectUrl: String!,
    $recurlyAction: String!,
    $isPoeEligible: Boolean,
    $recurlyPlanCode: String!,
    $recurlyPlanCodeLabel: String!,
    $salesforceValues: SalesforceFieldsInput!,
    $upgradeUrl: String,
    $maxBoardsUpgradeUrl: String,
    $specialOffersUrl: String,
    $isActive: Boolean!,
    $recurlyPlanCodePrice: Int,
    $meetingsEnabled: Boolean,
    $dualRecurlyAction: String,
    $recurlyPlanCodeTerm: String,
    $earlyConversionAndUpgradePlancode: String,
    $isFreemium: Boolean,
    $freemiumUpgradeOne: String,
    $freemiumUpgradeTwo: String,
    $isAcPlus: Boolean,
    $b2bFreeTrialMonthlyUpgrade1: String,
    $b2bFreeTrialAnnualUpgrade1: String,
    $b2bFreeTrialMonthlyUpgrade2: String,
    $b2bFreeTrialAnnualUpgrade2: String,
    $acPlusUpgradeOptionOne: String,
    $acPlusUpgradeOptionTwo: String,
    $b2bOnboardingCalendar: String,
    $deleteb2bOnboardingCalendar: Boolean,
    $b2cFreemiumMonthlyUpgrade1: String,
    $b2cFreemiumMonthlyUpgrade2: String,
    $b2cFreemiumMonthlyUpgrade3: String,
    $b2cFreemiumAnnualUpgrade1: String,
    $b2cFreemiumAnnualUpgrade2: String,
    $b2cFreemiumAnnualUpgrade3: String,
    $isLegacy: Boolean,
    $isUpgrade: Boolean,
    $recurlyPlanCodeDisplayName: String,
  ) {
    updateRecurlyPlan(
      accountType: $accountType,
      boardsCanJoinCount: $boardsCanJoinCount,
      checkoutRedirectUrl: $checkoutRedirectUrl,
      displayOrder: $displayOrder,
      boardSeatsAllowedCount: $boardSeatsAllowedCount,
      isDualCapable: $isDualCapable,
      isPoeEligible: $isPoeEligible,
      isPrivate: $isPrivate,
      lengthOfTrialInDays: $lengthOfTrialInDays,
      onboardingRedirectUrl: $onboardingRedirectUrl,
      recurlyAction: $recurlyAction,
      recurlyPlanCode: $recurlyPlanCode,
      recurlyPlanCodeLabel: $recurlyPlanCodeLabel,
      salesforceValues: $salesforceValues,
      upgradeUrl: $upgradeUrl,
      maxBoardsUpgradeUrl: $maxBoardsUpgradeUrl,
      specialOffersUrl: $specialOffersUrl
      isActive: $isActive,
      recurlyPlanCodePrice: $recurlyPlanCodePrice,
      recurlyPlanCodeTerm: $recurlyPlanCodeTerm,
      meetingsEnabled: $meetingsEnabled,
      dualRecurlyAction: $dualRecurlyAction,
      isLegacy: $isLegacy,
      earlyConversionAndUpgradePlancode: $earlyConversionAndUpgradePlancode,
      isFreemium: $isFreemium,
      freemiumUpgradeOne: $freemiumUpgradeOne,
      freemiumUpgradeTwo: $freemiumUpgradeTwo,
      isAcPlus: $isAcPlus,
      b2bFreeTrialMonthlyUpgrade1: $b2bFreeTrialMonthlyUpgrade1,
      b2bFreeTrialAnnualUpgrade1: $b2bFreeTrialAnnualUpgrade1,
      b2bFreeTrialMonthlyUpgrade2: $b2bFreeTrialMonthlyUpgrade2,
      b2bFreeTrialAnnualUpgrade2: $b2bFreeTrialAnnualUpgrade2,
      acPlusUpgradeOptionOne: $acPlusUpgradeOptionOne,
      acPlusUpgradeOptionTwo: $acPlusUpgradeOptionTwo,
      b2bOnboardingCalendar: $b2bOnboardingCalendar,
      deleteb2bOnboardingCalendar: $deleteb2bOnboardingCalendar,
      b2cFreemiumMonthlyUpgrade1: $b2cFreemiumMonthlyUpgrade1,
      b2cFreemiumMonthlyUpgrade2: $b2cFreemiumMonthlyUpgrade2,
      b2cFreemiumMonthlyUpgrade3: $b2cFreemiumMonthlyUpgrade3,
      b2cFreemiumAnnualUpgrade1: $b2cFreemiumAnnualUpgrade1,
      b2cFreemiumAnnualUpgrade2: $b2cFreemiumAnnualUpgrade2,
      b2cFreemiumAnnualUpgrade3: $b2cFreemiumAnnualUpgrade3,
      isUpgrade: $isUpgrade,
      recurlyPlanCodeDisplayName: $recurlyPlanCodeDisplayName,
    ) {
      recurlyPlanCode
    }
  }
`;

export function updateRecurlyPlanCall(planCode) {
  return API.graphql(graphqlOperation(updateRecurlyPlan, planCode));
}

export function adminSearchAllAccounts({ searchQuery, cognitoId }) {
  if (cognitoId) {
    return API.graphql(
      graphqlOperation(adminSearchByCognitoIdQuery, { cognitoId })
    );
  }
  return API.graphql(
    graphqlOperation(adminSearchAllAccountsQuery, { searchString: searchQuery })
  );
}

export function getRecurlyPlans({ ACCOUNT_TYPE, PURCHASING_CHANNEL }) {
  return API.graphql(
    graphqlOperation(getRecurlyPlansQuery, {
      accountType: ACCOUNT_TYPE,
      purchasingChannel: PURCHASING_CHANNEL,
    })
  );
}
export function getAllRecurlyPlans() {
  return API.graphql(graphqlOperation(getAllRecurlyPlansQuery));
}

export function getInsights() {
  return API.graphql(
    graphqlOperation(getInsightsQuery, {
      status: 'ALL',
    })
  );
}

export function getInsight(id) {
  return API.graphql(
    graphqlOperation(getInsightQuery, {
      id,
    })
  );
}

export function addInsight(payload) {
  return API.graphql(graphqlOperation(addInsightMutation, payload));
}

export function updateInsight(payload) {
  return API.graphql(graphqlOperation(updateInsightMutation, payload));
}

export function getAdminUsers() {
  return API.graphql(graphqlOperation(getAdminsQuery));
}

export function getAdmin(id) {
  return API.graphql(
    graphqlOperation(getAdminQuery, {
      id,
    })
  );
}

export function addAdmin(payload) {
  return API.graphql(graphqlOperation(addAdminMutation, payload));
}

export function updateAdmin(payload) {
  return API.graphql(graphqlOperation(updateAdminMutation, payload));
}

export function updateAdminAccountStatus(payload) {
  return API.graphql(
    graphqlOperation(updateAdminAccountStatusMutation, payload)
  );
}

export function getNewAdvisors({ COMAPNY_ID, OFFSET }) {
  return API.graphql(
    graphqlOperation(getNewAdvisorsQuery, {
      companyId: COMAPNY_ID,
      offset: OFFSET,
    })
  );
}

export function inviteToJoin({ COMAPNY_ID, ADVISOR_ID }) {
  return API.graphql(
    graphqlOperation(adminInviteToJoinMutation, {
      advisorId: ADVISOR_ID,
      companyId: COMAPNY_ID,
    })
  );
}

export function bulkInviteFinished({ COMPANY_ID }) {
  return API.graphql(
    graphqlOperation(adminBulkInviteFinishedMutation, {
      companyId: COMPANY_ID,
    })
  );
}

export function adminSignUp({
  BOARD_SEATS_ALLOWED_COUNT,
  COMPANY_NAME,
  EMAIL,
  GIVEN_NAME,
  ONBOARDING_EXPERIENCE_UPSELL,
  PHONE_COUNTRY_CODE,
  PHONE_NUMBER,
  POSTAL_CODE,
  RECURLY_ACCOUNT_CODE,
  RECURLY_BILLING_TOKEN,
  RECURLY_PLAN_CODE,
  SURNAME,
  URL_OF_CHECKOUT_PAGE,
  USER_SEGMENT,
}) {
  return API.graphql(
    graphqlOperation(adminSignUpMutation, {
      boardSeatsAllowedCount: BOARD_SEATS_ALLOWED_COUNT,
      companyName: COMPANY_NAME,
      email: EMAIL,
      givenName: GIVEN_NAME,
      onboardingExperienceUpsell: ONBOARDING_EXPERIENCE_UPSELL,
      phoneCountryCode: PHONE_COUNTRY_CODE,
      phoneNumber: PHONE_NUMBER,
      postalCode: POSTAL_CODE,
      recurlyAccountCode: RECURLY_ACCOUNT_CODE,
      recurlyBillingToken: RECURLY_BILLING_TOKEN,
      recurlyPlanCode: RECURLY_PLAN_CODE,
      surName: SURNAME,
      urlOfCheckoutPage: URL_OF_CHECKOUT_PAGE,
      userSegment: USER_SEGMENT,
    })
  );
}

export function updateAdvisorAccountStatus({ USER_ID, userStatus }) {
  return API.graphql(
    graphqlOperation(updateAdvisorAccountStatusMutation, {
      id: USER_ID,
      accountStatus: userStatus,
    })
  );
}

export function updateCompanyContactAccountStatus({ USER_ID, userStatus }) {
  return API.graphql(
    graphqlOperation(updateCompanyContactAccountStatusMutation, {
      id: USER_ID,
      accountStatus: userStatus,
    })
  );
}
export function deleteAdvisorCCPA({ USER_ID, EMAIL }) {
  return API.graphql(
    graphqlOperation(deleteAdvisorCCPAMutation, {
      advisorId: USER_ID,
      email: EMAIL,
    })
  );
}

export function deleteCompanyCCPA({ COMPANY_ID }) {
  return API.graphql(
    graphqlOperation(deleteCompanyCCPAMutation, {
      companyId: COMPANY_ID,
    })
  );
}

export function pauseCompanyNotice({ COMAPNY_ID }) {
  return API.graphql(
    graphqlOperation(sendPauseCompanyNoticeMutation, {
      companyId: COMAPNY_ID,
    })
  );
}

// export function updateCloudsearchService() {
//   return API.graphql(
//     graphqlOperation(
//       `
//     mutation(
//       $type: String!
//     ) {
//       updateCloudsearch(
//         type: $type
//       )
//     }
//     `,
//       {
//         type: 'Advisor',
//       }
//     )
//   );
// }
const fetchWithTimeout = (url, options, timeout = 29000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Timeout')), timeout)
    )
  ]);
};

export async function updateCloudsearchService() {
  try {
    const advisor = await fetchWithTimeout(process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: {
        'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        mutation(
          $type: String!
        ) {
          updateCloudsearch(
            type: $type
          )
        }
        `,
        variables: {
          type: 'Advisor',
        },
      }),
    }, 29000);  // Timeout de 29 segundos

    const response = await advisor.json();
    return response;

  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

// export async function updateCloudsearchService() {
//   const query = `
//     mutation($type: String!) {
//       updateCloudsearch(type: $type)
//     }
//   `;

//   const variables = {
//     type: 'Advisor',
//   };

//   const response = await fetch(process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, {
//     method: 'POST',
//     headers: {
//       'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       query,
//       variables,
//     }),
//   });

//   const responseData = await response.json();

//   if (responseData.errors) {
//     console.error('GraphQL errors:', responseData.errors);
//     throw new Error('Error updating Cloudsearch service');
//   }

//   return responseData.data.updateCloudsearch;
// }

export function searchCloudsearch({ searchTerm }) {
  return API.graphql(
    graphqlOperation(
      `
    mutation(
      $searchTerm: String!
    ) {
      searchCloudsearch(
        searchTerm: $searchTerm
      )
    }
    `,
      {
        searchTerm: searchTerm,
      }
    )
  );
}

export function updateCompanyFreeTrialEndsOn({ companyId, freeTrialEndsOn }) {
  return API.graphql(
    graphqlOperation(editFreeTrialEndsOn, {
      companyId: companyId,
      freeTrialEndsOn: freeTrialEndsOn,
    })
  );
}
